body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.logo h2 {
  font-size: 18px;
  margin: 0;
  color: #fff;
}

.dropdown {
  position: relative;
}

.dropdown-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.dropdown-button span {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #fff;
  margin-bottom: 4px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  list-style: none;
  padding: 8px 0;
  background-color: #333;
  margin-top: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown.open .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  margin-right: 8px;
}

.dropdown-menu a {
  display: block;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  padding: 8px 16px;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-info h3 {
  margin-right: 8px;
  color: #fff;
}

button {
  padding: 6px 12px;
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
  nav {
    flex-wrap: wrap;
  }

  .dropdown {
    order: 2;
    margin-top: 10px;
  }

  .profile-info {
    order: 1;
    margin-top: 10px;
  }
}

button {
  padding: 6px 12px;
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Additional Styles */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-heading {
  font-size: 24px;
  margin-bottom: 16px;
  color: #333;
}

.login-button {
  padding: 10px 20px;
  background-color: #61dafb;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #45b6d9;
}